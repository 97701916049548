<template>
    <v-card min-height="500px" max-height="500px">
        <v-card-text>
            <ValidationObserver ref="observer" v-slot="{}">
                <v-form>
                    <v-row dense>
                        <v-col>
                            <CustomLabel label="Name" for="name" required />
                            <CustomTextField
                                id="name"
                                inputPlaceholder="Name"
                                :inputValue="customer.name"
                                validationName="name"
                                validationRules="required"
                                @input="updateForm($event, 'customer.name')"
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col>
                            <CustomLabel
                                for="abbreviation"
                                label="Abbreviation"
                                required
                            />
                            <CustomTextField
                                id="abbreviation"
                                inputPlaceholder="Abbreviation"
                                :inputValue="customer.abbreviation"
                                validationName="abbreviation"
                                validationRules="required"
                                @input="
                                    updateForm($event, 'customer.abbreviation')
                                "
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12">
                            <CustomLabel for="email" label="Email" required />
                            <CustomTextField
                                id="email"
                                inputPlaceholder="Email Address"
                                :inputValue="customer.email"
                                validationName="email"
                                validationRules="required"
                                @input="updateForm($event, 'customer.email')"
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col>
                            <CustomLabel
                                for="first_name"
                                label="First Name"
                                required
                            />
                            <CustomTextField
                                id="first_name"
                                inputPlaceholder="First Name"
                                :inputValue="customer.first_name"
                                validationName="first_name"
                                validationRules="required"
                                @input="
                                    updateForm($event, 'customer.first_name')
                                "
                            />
                        </v-col>

                        <v-col>
                            <CustomLabel
                                for="last_name"
                                label="Last Name"
                                required
                            />
                            <CustomTextField
                                id="last_name"
                                inputPlaceholder="Last Name"
                                :inputValue="customer.last_name"
                                validationName="Last Name"
                                validationRules="required"
                                @input="
                                    updateForm($event, 'customer.last_name')
                                "
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col>
                            <CustomLabel for="last_name" label="Full Name" />
                            <CustomTextField
                                id="full_name"
                                inputPlaceholder="Full Name"
                                :inputValue="customer.full_name"
                                @input="
                                    updateForm($event, 'customer.full_name')
                                "
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col>
                            <CustomLabel for="rate" label="Rate" required />
                            <CustomTextField
                                id="rate"
                                inputPlaceholder="Rate"
                                :inputValue="customer.rate"
                                validationName="rate"
                                validationRules="required"
                                @input="updateForm($event, 'customer.rate')"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <CustomButton
                                btnLabel="Cancel"
                                tooltipMsg="Cancel"
                                @click="hideDialog"
                            />
                            <CustomButton
                                btnColour="primary"
                                :btnIsLoading="updateOps.isLoading"
                                btnLabel="Save"
                                tooltipMsg="Save"
                                @click="updateItem(customer)"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("customersAdminMgmt", ["customer", "updateOps"]),
    },
    data: () => ({}),
    methods: {
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("customersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("customersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
        async updateItem(payload) {
            let valid = this.$refs.observer.validate();

            if (!valid) {
                return;
            }

            let response = await this.$store.dispatch(
                "customersAdminMgmt/updateItem",
                {
                    id: payload.id,
                    customer: {
                        name: payload.name,
                        abbreviation: payload.abbreviation,
                        email: payload.email,
                        first_name: payload.first_name,
                        last_name: payload.last_name,
                        full_name: payload.full_name,
                        rate: payload.rate,
                    },
                }
            );

            if (response) {
                this.$refs.observer.reset();
            }
        },
        hideDialog() {
            this.$store.commit("customersAdminMgmt/HIDE_VIEW_OPS_DIALOG");
            this.$store.commit("customersAdminMgmt/FLUSH_ITEM");
        },
    },
    name: "TabCustomerProfile",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>